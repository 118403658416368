/*
 *  Document   : style.css
 *  Author     : RedStar Template
 *  Description: This is a main style scss file for import all scss files.
 *
 *  Structure (with shortcodes):
                 [1. Common ]
                 [2. Fonts ]
                 [3. Components ]
                 [4. Apps]
                 [5. Pages ]
                 [6. Pugins ]
                 [7. UI ]
                 [8. Browser ]


/*                 [1. Common ]                   			*/
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// /* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
//@import "node_modules/ngx-om-perfect-scrollbar/css/perfect-scrollbar.css";
@import "common/_variables";
@import "common/_mixins";
@import "common/_customanimate.scss";
@import "common/_general.scss";
@import "common/_demo.scss";
@import "common/_helpers.scss";
@import "common/_media.scss";
@import "common/_animation.scss";

/*                 [2. Fonts]                   				*/
//@import "fonts/_material.scss";
@import "fonts/fontawesome/fontawesome.scss";
@import "fonts/fontawesome/regular.scss";
@import "fonts/fontawesome/solid.scss";
@import "fonts/fontawesome/brands.scss";

/*                 [3. Components ]                   		*/
@import "components/_breadcrumbs.scss";
@import "components/_checkboxradio.scss";
@import "components/_dropdownmenu.scss";
@import "components/_feed.scss";
@import "components/_formcomponents.scss";
@import "components/_infobox.scss";
@import "components/_inputformgroup.scss";
@import "components/_labels.scss";
@import "components/_leftsidebaroverlay.scss";
@import "components/_navbar";
@import "components/_navtabs.scss";
@import "components/_noticeboard.scss";
@import "components/_rightsidebar.scss";
@import "components/_searchbar.scss";
@import "components/_switch.scss";
@import "components/_thumbnails.scss";
@import "components/_todo.scss";

/*                 [4. Apps]                   				*/
@import "apps/_calendar.scss";
@import "apps/_chat.scss";
@import "apps/_contactlist.scss";
@import "apps/_contactgrid.scss";
@import "apps/_dragdrop.scss";
@import "apps/_task.scss";

/*                 [5. Pages ]                   				*/
@import "pages/_dashboard.scss";
@import "pages/_inbox.scss";
@import "pages/_pricing.scss";
@import "pages/_profile.scss";
@import "pages/_timeline.scss";
@import "pages/_extra_pages.scss";

/*                 [6. Pugins ]                   				*/
@import "plugins/_carousel.scss";
@import "plugins/_charts.scss";
@import "plugins/_formwizard.scss";
@import "plugins/_imagegallery.scss";
@import "plugins/_maps.scss";
@import "plugins/_tables.scss";

/*                 [7. UI ]                   */
@import "ui/_alerts.scss";
@import "ui/_badgelistgroupitem.scss";
@import "ui/_buttons.scss";
@import "ui/_card.scss";
@import "ui/_collapse.scss";
@import "ui/_dialogs.scss";
@import "ui/_expansion.scss";
@import "ui/_mediaobject.scss";
@import "ui/_modals.scss";
@import "ui/_pageloader.scss";
@import "ui/_pagination.scss";
@import "ui/_panels.scss";
@import "ui/_preloaders.scss";
@import "ui/_progressbars.scss";
@import "ui/_slider.scss";
@import "ui/_snackbar.scss";
@import "ui/_tabs.scss";
@import "ui/_tooltippopovers.scss";
@import "ui/_ckeditor.scss";

/*                 [8. Browser ]                   			*/  
@import "browser/_ie10.scss";
@import "browser/_ie11.scss";
@import "override.scss";
@import "node_modules/ngx-toastr/toastr";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "node_modules/ngx-bar-rating/themes/br-default-theme";
@import "node_modules/ngx-bar-rating/themes/br-horizontal-theme";
@import "node_modules/ngx-bar-rating/themes/br-movie-theme";
@import "node_modules/ngx-bar-rating/themes/br-stars-theme";
@import "node_modules/ngx-bar-rating/themes/br-vertical-theme";
body {
  color: #555 !important;
}

::ng-deep .mat-dialog-container {
  box-shadow: unset !important;
}
.mat-mdc-tab {
  letter-spacing: 0px !important;
}