.mat-tab-body-content {
  overflow: hidden !important;
}
#floatingNotifications {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.toast-bottom-right {
  right: 12px !important  ;
  bottom: 100px !important;
}
.cdk-overlay-pane {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute !important;
  top: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
.cdk-overlay-pane::-webkit-scrollbar {
  display: none;
}
.cdk-overlay-pane {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.mat-dialog-container {
  border-radius: 12px !important;
}
